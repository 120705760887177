import React from 'react';
import { MenuListWrapper } from './MenuList.styles';
import { NavLink } from 'react-router-dom';

const MenuList = ({ list, windowWidth, setOpen }) => {
  // setOpen && setOpen(false)

  const handleMenuclick = () => {
    if (setOpen) {
      setOpen(false);
    }
    window.scrollTo(0, 0);
  };

  return (
    <MenuListWrapper className={`${windowWidth < 768 ? 'mobile-list' : ''}`}>
      <ul>
        {list?.map((menu_item) => (
          <li key={menu_item?.title} onClick={() => handleMenuclick()}>
            <NavLink
              className={({ isActive }) => (isActive ? 'active' : '')}
              to={menu_item?.target}
            >
              {menu_item?.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </MenuListWrapper>
  );
};

export default MenuList;
