import React from 'react';
import { PrivacyPolicyWrapper } from '../PrivacyPolicy/PrivacyPolicy.styles';
import { Link } from 'react-router-dom';
import { Container } from '../../globalStyles';

const TermsConditions = () => {
  return (
    <PrivacyPolicyWrapper>
      <Container>
        <p>
          These Terms & Conditions ("Agreement") constitute a legally binding
          agreement made between you, whether personally or on behalf of an
          entity ("you") and Just Ask Him (DRI Travel) Just Ask Him ("we", "us"
          or "our"), concerning your access to and use of the{' '}
          <Link to={'/'}>https://justaskhim.org</Link> website ("Website") and
          the services we offer ("Services").
        </p>
        <p>
          We may amend this Agreement at any time by posting the amended terms
          on our Website. We may or may not post notices on the homepage of our
          Website when such changes occur.
        </p>
        <h2>Content Disclaimer</h2>
        <p>
          Postings on our Website are made at such times as Just Ask Him
          determines in its discretion. You should not assume that the
          information contained on our Website has been updated or otherwise
          contains current information. Just Ask Him do not consistently review
          past postings to determine whether they remain accurate and
          information contained in such postings may have been superseded. THE
          INFORMATION AND MATERIALS IN OUR WEBSITE ARE PROVIDED FOR YOUR REVIEW
          IN ACCORDANCE WITH THE NOTICES, TERMS AND CONDITIONS SET FORTH HEREIN.
          THESE MATERIALS ARE NOT GUARANTEED OR REPRESENTED TO BE COMPLETE,
          CORRECT OR UP TO DATE. THESE MATERIALS MAY BE CHANGED FROM TIME TO
          TIME WITHOUT NOTICE.
        </p>
        <h2>Our Services and Disclaimer</h2>
        <p>
          You understand that we provide information based on Jesus’s words and
          character from the Bible. You acknowledge that we are not employees
          nor direct representatives of any church or religious organization. 
          You understand that service providers may be paid positioning and Just
          Ask Him does not guarantee, warranty, nor represent their services. 
          The content on the Just Ask Him website is for information purposes
          only.
        </p>
        <h2>Refunds for Services</h2>
        <p>
          We issue refunds for purchased Policies within 7 days of the original
          purchase of the Policies.
        </p>
        <h2>No Warranties; Exclusion of Liability; Indemnification</h2>
        <p>
          OUR WEBSITE IS OPERATED BY Just Ask Him{' '}
          <Link to={'/'}>(https://justaskhim.org)</Link> ON AN "AS IS," "AS
          AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND.
          TO THE FULLEST EXTENT PERMITTED BY LAW, Just Ask Him SPECIFICALLY
          DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, INCLUDING ALL
          IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT FOR OUR WEBSITE AND ANY
          CONTRACTS AND SERVICES YOU PURCHASE THROUGH IT. Just Ask Him SHALL NOT
          HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN
          THE CONTENT OF OUR WEBSITE, FOR YOUR ACTION OR INACTION IN CONNECTION
          WITH OUR WEBSITE OR FOR ANY DAMAGE TO YOUR COMPUTER OR DATA OR ANY
          OTHER DAMAGE YOU MAY INCUR IN CONNECTION WITH OUR WEBSITE. YOUR USE OF
          OUR WEBSITE AND OR SERVICES ARE AT YOUR OWN RISK. IN NO EVENT SHALL
          EITHER Just Ask Him <Link to={'/'}>(https://justaskhim.org)</Link> OR
          THEIR AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
          SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY
          CONNECTED WITH THE USE OF OUR WEBSITE, CONTRACTS AND SERVICES
          PURCHASED CONNECTED TO OUR WEBSITE, THE DELAY OR INABILITY TO USE OUR
          WEBSITE OR OTHERWISE ARISING IN CONNECTION WITH OUR WEBSITE, CONTRACTS
          OR RELATED SERVICES, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY
          OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES.
          IN NO EVENT SHALL Just Ask Him LIABILITY FOR ANY DAMAGE CLAIM EXCEED
          THE AMOUNT PAID BY YOU TO Just Ask Him FOR THE TRANSACTION GIVING RISE
          TO SUCH DAMAGE CLAIM.
        </p>
        <p>
          WITHOUT LIMITING THE FOREGOING, Just Ask Him DO NOT REPRESENT OR
          WARRANT THAT THE INFORMATION ON THE WEBITE IS ACCURATE, COMPLETE,
          RELIABLE, USEFUL, TIMELY OR CURRENT OR THAT OUR WEBSITE WILL OPERATE
          WITHOUT INTERRUPTION OR ERROR.
        </p>
        <p>
          YOU AGREE THAT ALL TIMES, YOU WILL LOOK TO BUSINESSES AND SERVICE
          PROVIDERS FROM WHOM YOU PURCHASE SERVICES FOR ANY CLAIMS OF ANY
          NATURE, INCLUDING LOSS, DAMAGE, OR WARRANTY. Just Ask Him AND THEIR
          RESPECTIVE AFFILIATES MJAHE NO REPRESENTATION OR GUARANTEES ABOUT ANY
          CONTRACTS AND SERVICES OFFERED THROUGH OUR WEBSITE.
        </p>
        <p>
          Just Ask Him <Link to={'/'}>(https://justaskhim.org)</Link> MJAHES NO
          REPRESENTATION THAT CONTENT PROVIDED ON OUR WEBSITE, CONTRACTS, OR
          RELATED SERVICES ARE APPLICABLE OR APPROPRIATE FOR USE IN ALL
          JURISDICTIONS.
        </p>
        <h2>Indemnification</h2>
        <p>
          You agree to defend, indemnify and hold Just Ask Him{' '}
          <Link to={'/'}>(https://justaskhim.org)</Link> harmless from and
          against any and all claims, damages, costs and expenses, including
          attorneys' fees, arising from or related to your use of our Website or
          any Contracts or Services you purchase through it.
        </p>
        <h2>Third-Party Websites Links & Content</h2>
        <p>
          Our Website may contain links to other websites ("Third-Party
          Websites") owned or operated by parties other than Just Ask Him{' '}
          <Link to={'/'}>(https://justaskhim.org)</Link>. Such links are
          provided for your reference only. Just Ask Him{' '}
          <Link to={'/'}>(https://justaskhim.org)</Link>  does not monitor or
          control outside Third-Party Websites and is not responsible for their
          content. Just Ask Him is{' '}
          <Link to={'/'}>(https://justaskhim.org)</Link>  inclusion of links to
          a Third-Party Website does not imply any endorsement of the material
          on our Website or, unless expressly disclosed otherwise, any
          sponsorship, affiliation or association with its owner, operator or
          sponsor, nor does Just Ask Him inclusion of the links imply that Just
          Ask Him is authorized to use any trade name, trademark, logo, legal or
          official seal, or copyrighted symbol that may be reflected in the
          linked Third-Party Website.
        </p>
        <h2>Limited License</h2>
        <p>
          Just Ask Him grants you a non-exclusive, non-transferable, revocable
          license to access and use our Website and Services through our
          Website, strictly in accordance with our Agreement.
        </p>
        <h2>Copyrights and Trademarks</h2>
        <p>
          Unless otherwise noted, all materials including without limitation,
          logos, brand names, images, designs, photographs, video clips and
          written and other materials that appear as part of our Website are
          copyrights, trademarks, service marks, trade dress and/or other
          intellectual property whether registered or unregistered
          ("Intellectual Property") owned, controlled or licensed by Just Ask
          Him <Link to={'/'}>(https://justaskhim.org)</Link>. Our Website as a
          whole is protected by copyright and trade dress. Nothing on our
          Website should be construed as granting, by implication, estoppel or
          otherwise, any license or right to use any Intellectual Property
          displayed or used on our Website, without the prior written permission
          of the Intellectual Property owner. Just Ask Him aggressively enforces
          its intellectual property rights to the fullest extent of the law. The
          names and logos of Just Ask Him, may not be used in any way, including
          in advertising or publicity pertaining to distribution of materials on
          our Website, without prior, written permission from Just Ask Him. Just
          Ask Him <Link to={'/'}>(https://justaskhim.org)</Link>  prohibits use
          of any logo of Just Ask Him or any of its affiliates as part of a link
          to or from any Just Ask Him{' '}
          <Link to={'/'}>(https://justaskhim.org)</Link>  Intellectual Property
          requires proper acknowledgment. Other product and company names
          mentioned in our Website may be the Intellectual Property of their
          respective owners.
        </p>
      </Container>
    </PrivacyPolicyWrapper>
  );
};

export default TermsConditions;
