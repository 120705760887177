import styled from 'styled-components';
import { color } from '../../assets/css/variable';

export const PrivacyPolicyWrapper = styled.div`
  padding: 40px 0;
  width: 100%;
  font-size: 20px;
  line-height: calc(20px * 1.4);
  color: ${color.blackColor};
  @media screen and (max-width: 991px) {
    font-size: 18px;
    line-height: calc(18px * 1.4);
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: calc(16px * 1.4);
    padding: 30px 0;
  }
  p {
    margin-top: 0;
  }
  h2 {
    font-size: 28px;
    line-height: calc(28px * 1.4);
    margin: 20px 0;
    display: inline-block;
    @media screen and (max-width: 991px) {
      font-size: 24px;
      line-height: calc(24px * 1.4);
    }
    @media screen and (max-width: 767px) {
      font-size: 22px;
      line-height: calc(22px * 1.4);
      margin: 10px 0;
    }
  }
  a {
    color: blue;
    cursor: pointer !important;
  }
  ul {
    li {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: -18px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: ${color.blackColor};
      }
    }
  }
  ol,
  ul {
    padding-left: 40px;
    @media screen and (max-width: 767px) {
      padding-left: 30px;
    }
    li {
      margin-bottom: 15px;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
      ol {
        margin-top: 20px;
        @media screen and (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
    }
  }
`;
