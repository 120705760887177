import React from 'react';
import { PrivacyPolicyWrapper } from './PrivacyPolicy.styles';
import { Container } from '../../globalStyles';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyWrapper>
      <Container>
        <p>
          Privacy is important to us, and we are strongly committed to being
          transparent and fair in our processing of personal data.  This Privacy
          Policy describes how we – Just Ask HIme, (together with its affiliated
          site - “Just Ask him”, “we”, “our” or “us”) collect, store, use, and
          disclose personal data regarding individuals (“you”) who:
        </p>
        <ul>
          <li>
            visit or otherwise interact with our website
            <strong>(“Visitors”)</strong>, available at justaskhim.org or any
            other website, webpage, email, text message or online ad under our
            control (collectively - the “Site”)
          </li>
          <li>
            are our prospective or existing partners
            <strong>(“Partners”)</strong>; or
          </li>
          <li>
            use our social engagement and content personalization platform, or
            any of our related services (collectively with the Site - the
            “Services”), at any of our partner’s websites and digital properties
            (“Users” and “Partner Sites”, respectively
          </li>
        </ul>
        <p>
          If you are our Visitor or Partner, please read this Privacy Policy
          carefully and make sure that you fully understand and agree to it. You
          are not legally required to provide us with any personal data, and may
          do so (or avoid doing so) at your own free will. If you do not wish to
          provide us with your personal data, or to have it processed by us or
          any of our Service Providers (defined below), please avoid any
          interaction with us or use of our Services. If you are a User of one
          of our Partner Sites, you also choose not to provide us with
          “optional” Personal Data, or to request to delete or opt-out of
          certain processing activities (see Section 8), but please keep in mind
          that limiting our processing may also result in us not being able to
          provide you with the full range of our services or with the best user
          experience when using our Services.
        </p>
        <h2>1. Data Collection</h2>
        <p>
          We collect data regarding our Visitors, Users and Partners. Such data
          is typically collected and generated through the Visitor’s or User’s
          interaction with our Services, through automatic means, directly from
          such Visitor or User, from our Partners, or from other third parties.
        </p>
        <p>
          Specifically, we collect the following categories of data (which, to
          the extent it relates to an identified or identifiable individual,
          will be deemed as <strong>“Personal Data“</strong>):
        </p>
        <ol>
          <li>
            <strong>Data automatically collected or generated:</strong> When you
            visit, interact with or use our Services, we may collect or generate
            certain technical data about you. We collect or generate such data
            either independently or with the help of third party services (as
            detailed in Section 4 below), including through the use of “cookies”
            and other tracking technologies (as further detailed in Section 5
            below).Such data mainly consists of connectivity, technical and
            aggregated usage data, such as IP addresses, non-identifying data
            regarding a device, operating system, browser version, locale and
            language settings used, the cookies and pixels installed on such
            device and the activity (clicks and other interactions) of Visitors
            and Users in connection with our Services.
          </li>
          <li>
            <strong>Data received from you:</strong> You may provide us with
            Personal Data such as your name, contact details (such as e-mail,
            phone and address), account login details (such as username and
            hashed passwords), workplace and position, profile picture,
            interests, preferences, and free-form description (collectively,
            <strong>“Account Data“</strong>), as well as any other data you
            choose to provide when you use our Services, create a user account,
            publish content and interact with others via our platform, or
            contact us.Partners may provide us additional Account Data such as
            their billing details, business needs and preferences. To the extent
            that such data concerns a non-human entity (e.g. the bank account of
            a company or business), we will not regard it as “Personal Data” and
            this Privacy Policy shall not apply to it.In the event that a User
            registers to the Services through their existing third-party Social
            Network Account (namely the User’s Facebook, Twitter, Microsoft or
            Google+ account, or other approved social platforms or networks), or
            through a “Single Sign-On” (SSO) mechanism implemented by any
            Partner Site, then such third-party social network or Partner Site
            may provide us with certain information about such User, as is
            stored in and/or made available by the User through their Social
            Network or Partner Site Account. This includes the User’s name,
            public profile picture, URL address, Social Network Account User ID,
            e-mail address, date of birth, gender, occupation or work
            information, education and other information which the User made
            public. This Personal Data is automatically provided to and stored
            by OpenWeb when registration to the Services is conducted via the
            User’s Social Network or Partner Site Account.
          </li>
          <li>
            <strong>Data received from our Partners and Users:</strong> our
            Partners and Users may provide us with data concerning their
            friends, community members, colleagues or employees, so we could
            contact them regarding our Services (e.g. to offer or provide them
            with our Services). Such data usually refers to such individuals’
            name, role, company and work or personal contact details, as
            relevant, however additional data may be provided at the Partner’s
            discretion.
          </li>
          <li>
            <strong>Data received from third parties:</strong> we may receive
            your Personal Data from other sources. For example, if you
            participate in an event or webinar that we sponsor or participate
            in, we may receive your Personal Data from the event organizers. We
            may also receive your contact and professional details (e.g., your
            name, company, position, contact details and professional
            experience, preferences and interests) from our business partners or
            services provides, and through the use of tools and channels
            commonly used for connecting between companies and individuals in
            order to explore potential business and employment opportunities,
            such as LinkedIn.
          </li>
        </ol>
        <h2>2. Data Uses</h2>
        <p>
          We use Personal Data as necessary for the performance of our Services;
          to comply with applicable law; and to support our legitimate interests
          in maintaining and improving our Services and offerings, understanding
          how our Services are used, optimizing our marketing, advertising,
          customer service and support operations, and protecting and securing
          our Visitors, Users, Partners, ourselves and our Services, as well as
          members of the general public.
        </p>
        <p>
          If you reside or are using the Services (i) in a territory governed by
          privacy laws which determine that “consent” is the only legal basis
          for the processing of Personal Data (in general, or specifically with
          respect to the types of Personal Data you choose to share via the
          Services) or (ii) in the EEA and provide us with Personal Data, the
          processing of which requires “consent” under applicable law (e.g.
          ‘special categories’ under the GDPR, or use of cookies under the
          e-Privacy directive and related local legislation), your acceptance of
          our Terms of Service and of this Privacy Policy will be deemed as your
          consent to the processing of your Personal Data for all purposes
          detailed in this Privacy Policy. If you wish to revoke such consent,
          please contact us at  {''}
          <Link to={'mailto:support@justaskhim.org'}>
            support@justaskhim.org
          </Link>
           or follow the instructions in Sections 6 or 8 below (as the case may
          be).
        </p>
        <p>Specifically, we use Personal Data for the following purposes:</p>
        <ol>
          <li>To facilitate, operate, and provide our Services;</li>
          <li>
            To manage and deliver personalized advertisements and content more
            effectively, including contextual, interests-based and other types
            of targeted advertising based on the User’s activity on our Services
            and Partner Sites, their preferences or other data available to us
            or to our business partners, including for re-targeting purposes;
          </li>
          <li>
            To authenticate the identity of our Users, and to allow them access
            to our Services;
          </li>
          <li>
            To provide our Visitors, Users and Partners with assistance and
            support;
          </li>
          <li>
            To further develop, customize and improve the Services and your user
            experience, based on common or personal preferences, experiences and
            difficulties;
          </li>
          <li>
            To contact our Visitors, Users and Partners with general or
            personalized service-related messages and calls, and promotional
            messages and calls (as further described in Section 6 below); and to
            facilitate, sponsor and offer certain events and promotions;
          </li>
          <li>
            To support and enhance our data security and content compliance
            measures, including for the purposes of preventing and mitigating
            the risks of fraud, abuse, error or any illegal or prohibited
            activity;
          </li>
          <li>
            To create aggregated statistical data, inferred non-personal data or
            anonymized or pseudonymized data (rendered non-personal), which we
            or our business partners may use to provide and improve our
            respective services; and
          </li>
          <li>To comply with any applicable laws and regulations.</li>
        </ol>
        <h2>3. Data Location & Retention</h2>
        <ol>
          <li>
            <strong>Data Location:</strong> We and our authorized Service
            Providers (defined below) maintain, store and process Personal Data
            in the United States, the European Union, and other locations as
            reasonably necessary for the proper performance and delivery of our
            Services, or as may be required by law.  Just Ask Him (DRI Travel)
            is headquartered in Dallas County, Texas, a jurisdiction which is
            considered by the European Commission to be offering an adequate
            level of protection for the Personal Data of EU Member State
            residents. While privacy laws may vary between jurisdictions, Just
            Ask Him and its affiliates and Service Providers are each committed
            to protect Personal Data in accordance with this Privacy Policy,
            customary industry standards, and such appropriate lawful mechanisms
            and contractual terms requiring adequate data protection, regardless
            of any lesser legal requirements that may apply in the jurisdiction
            to which such data is transferred.
          </li>
          <li>
            <strong>Data Retention:</strong> We typically retain Users’ Personal
            Data for as long as their account is active, or as long as we
            consider necessary for the purposes described herein (which may
            extend even after their account is inactivated). We keep Visitors’,
            Users’ and Partners’ Personal Data for as long as reasonably
            necessary in order to maintain and expand our relationship and to
            provide them with our Services. We will also retain your Personal
            Data for as long as is required in order to comply with our legal
            and contractual obligations, or to protect ourselves from any
            potential disputes (i.e. as required by laws applicable to
            log-keeping, records and bookkeeping, and in order to have proof and
            evidence concerning our relationship, should any legal issues arise
            following your discontinuance of use), in accordance with our
            internal data retention policy. Please note that except as required
            by applicable law, we will not be obligated to retain your Personal
            Data for any particular period, and we are free to securely delete
            it for any reason and at any time, with or without notice to you. If
            you have any questions about our data retention policy, please
            contact us by email at {''}
            <Link to={'mailto:support@justaskhim.org'}>
              support@justaskhim.org
            </Link>
          </li>
        </ol>
        <h2>4. Data Sharing</h2>
        <ol>
          <li>
            <strong>Legal Compliance:</strong> We may disclose or allow
            government and law enforcement officials access to your Personal
            Data, in response to a subpoena, search warrant or court order (or
            similar requirement), or in compliance with applicable laws and
            regulations. Such disclosure or access may occur if we believe in
            good faith that we are legally compelled to do so, or that
            disclosure is appropriate in connection with efforts to investigate,
            prevent, or take action regarding actual or suspected illegal
            activity, fraud, or other wrongdoing.
          </li>
          <li>
            <strong>Service Providers:</strong> We may engage selected third
            party companies and individuals to perform services complementary to
            our own (e.g. hosting services, data analytics services, marketing
            and advertising services, data and cyber security services, payment
            processing services, user engagement services, e-mail distribution
            and monitoring services, text messaging services, session recording,
            content moderation, and our business, legal and financial advisors)
            (collectively, <strong>“Service Providers“</strong>). These Service
            Providers may have access to your Personal Data, depending on each
            of their specific roles and purposes in facilitating and enhancing
            our Services, and may only use it for such purposes.
          </li>
          <li>
            <strong>Sharing Personal Data with our Partners:</strong> We may
            share the Personal Data of Users who use our Services on a Partner
            Site, with the Partner operating such site (including data and
            communications concerning their user account), regardless of their
            account registration method. We may also share limited Personal Data
            of Users with our Partners for ad and content personalization
            purposes (see Section 5 below). Please note that JUST ASK HIM is not
            responsible for and does not control any further disclosure, use or
            monitoring by or on behalf of such Partner, that itself acts as the
            “Data Controller” of such data (as further described in Section 10
            below).
          </li>
          <li>
            <strong>Protecting Rights and Safety:</strong> We may share your
            Personal Data with others if we believe in good faith that this will
            help protect the rights, property or personal safety of AK, any of
            our Visitors, Users or Partners, or any members of the general
            public.
          </li>
          <li>
            <strong>JUST ASK HIM Subsidiaries and Affiliated Websites:</strong>{' '}
            We may share Personal Data internally within our group for the
            purposes described in this Privacy Policy. In addition, JUST ASK HIM
            or any of its subsidiaries or affiliates undergo any change in
            control or ownership, including by means of merger, acquisition or
            purchase of all or part of its assets, your Personal Data may be
            shared with the parties involved in such event. If we believe that
            such change in control might materially affect your Personal Data
            then stored with us, we will notify you of this event and the
            choices you may have via e-mail or prominent notice on our Services.
          </li>
        </ol>
        <p>
          For the avoidance of doubt, JUST ASK HIM may share your Personal Data
          in additional manners, pursuant to your explicit approval, or if we
          are legally obligated to do so, or if we have successfully rendered
          such data non-personal and anonymous. We may transfer, share or
          otherwise use non-personal data at our sole discretion and without the
          need for further approval.
        </p>
        <h2>5. Cookies, Tracking Technologies and Advertising Uses</h2>
        <ol>
          <li>
            Our Services, our Partners and some of our Service Providers utilize
            “cookies”, anonymous identifiers, JavaScript, web beacons (including
            clear GIFs), HTML5 local storage, and other tracking technologies
            which help us provide, secure and improve our Services, personalize
            your experience and monitor the performance of our activities and
            campaigns.
          </li>
          <li>
            Our Services also utilize advertising for third party services and
            products. We and our advertising Partners use certain data about you
            in order to improve our advertising practices and make the ads we
            deliver more effective, relevant and enjoyable, including by
            deploying contextual, interest-based and other types of advertising,
            as well as targeting, ad measurement and attribution systems. The
            ads and content we or our advertising partners deliver may be shown
            within our Services or through other channels, such as third-party
            websites and applications.  Such data includes your IP address,
            cookies, pixels and other unique identifiers, data related to your
            connected Social Network Account, and other demographic and
            interests-based information that we or our advertising partners may
            have received from or about you, based on your consent or another
            legal basis (i.e., as required in order to provide you with our
            respective services, or based on the legitimate interests that we
            described in Section 2 above).
          </li>
          <li>
            When you use our Services, we may share information that we collect
            from you with our identity partners/service providers that we may
            store in our first-party cookie for our use in online and
            cross-channel advertising, and it may be shared with advertising
            companies to enable interest-based and targeted advertising. The
            information shared for such activities may include your email (in
            hashed and non-identifying form), IP address and information about
            your browser or operating system.
          </li>
          <li>
            Please note that this Privacy Policy does not apply to the
            processing of your data by our advertising partners and networks. We
            recommend that you review their privacy policies for more
            information.
          </li>
          <li>
            <strong>
              Our practices with respect to Do-Not-Track (DNT) signals:
            </strong>
             DNT signals are the result of a standardization initiative from
            2009 to create a standard signal which could be broadcasted by any
            web browser, and read by websites accessed through such browser, to
            note an individual’s preference not to be tracked online.  While the
            working group assigned to this standardization initiative by the
            World Wide Web Consortium (W3C) was disbanded in 2019, the
            California Online Privacy Protection Act (CalOPPA) still requires
            website publishers/operators to disclose whether they respond to web
            browsers’ ‘do not track’ (DNT) signals.
          </li>
          <li>
            Similar to most websites, the justaskhim.org website does not change
            its practices in response to a “Do Not Track” signal in the HTTP
            header from a browser, as unfortunately these are, from a technical
            perspective, not sufficiently reliable or standardized for us to
            warrant full adherence.  We do recommend the use of cookies for an
            optimal user experience of our Services, but if you choose to remove
            or block them we definitely respect your choice. Modern web browsers
            typically allow you to control cookies, including whether or not to
            accept them and to remove them. You may also set most browsers to
            notify you if you receive a cookie, or you may choose to block
            cookies entirely through your browser settings.
          </li>
          <li>
            Just Ask Him supports initiatives of greater transparency and
            control for users, and offers the following options for controlling
            the interest-based content and ads you receive:
            <ol>
              <li>
                If you reside in the EU and do not want to receive
                interest-based ads displayed by the Just Ask Him website, you
                can disable the display of such ads by visiting the EDAA
                consumer choice page at 
                <Link to={'https://youronlinechoices.eu/'} target='_blank'>
                  www.youronlinechoices.eu
                </Link>
                . You may also use the 
                <Link
                  to={'https://optout.networkadvertising.org/?c=1'}
                  target='_blank'
                >
                  NAI opt out tool
                </Link>
                , which will allow you to opt out of seeing targeted ads from
                other NAI-approved member companies, and for mobile opt outs,
                the NAI provides instructions to engage system-level opt outs in
                Android, iOS, and Windows Phone environments.
              </li>
              <li>
                If you reside in California and wish to opt-out of the “sale” of
                your personal information, as defined under the California
                Consumer Privacy Act (CCPA), please follow the instructions in
                Section 8 below.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          Please note that opting out will not prevent you from seeing ads
          sourced from us on our Services or on other websites, but it will
          affect the types of ads that you will see. These ads will no longer be
          tailored to your interests and instead will be chosen based on the
          particular website that you are viewing (i.e. contextually based ads).
          Because our tracking mechanisms operate at the device and browser
          level, to fully opt-out across devices, you will need to do so on each
          device and browser individually. Also, if your browsers are configured
          to reject cookies when you visit the EDAA consumer choice page,
          your-opt out may not be effective.
        </p>
        <h2>6. Communications</h2>
        <ol>
          <li>
            <strong>Service Communications:</strong> We may contact you with
            important information regarding our Services. For example, we may
            notify you (through any of the means available to us, e.g. phone,
            mobile, web notifications or e-mail) of changes or updates to our
            Services, billing issues, service changes, password-retrieval
            notices, etc. Typically, you will not be able to opt-out of
            receiving such service communications while using our Services, as
            they are integral to such use.
          </li>
          <li>
            <strong>Notifications and Promotional Communications:</strong> We
            may send you notifications, messages and other updates about new
            features, offerings, events and special opportunities or any other
            information we think our Users or Partners will find valuable. We
            may provide such notices through any of the contact means available
            to us, through the Services, or through our marketing campaigns on
            any other sites or platforms.  If you do not wish to receive such
            promotional communications, you may notify the Just Ask Him at any
            time by changing your communications preferences in your account, by
            following the “unsubscribe”, “stop”, “opt-out” or “change email
            preferences” instructions contained in the promotional
            communications you receive, or by sending an email to 
            <Link to={'mailto:support@justaskhim.org'}>
              support@justaskhim.org
            </Link>
            .
          </li>
        </ol>
        <h2>7. Data Security</h2>
        <p>
          In order to protect your Personal Data held with us and our Service
          Providers, we are using industry-standard physical, procedural and
          electronic security measures, including encryption as appropriate.
          However, please be aware that regardless of any security measures
          used, we cannot and do not guarantee the absolute protection and
          security of any Personal Data stored with us or with any third parties
          as described in Section 4 above.
        </p>
        <h2>8. Data Subject Rights</h2>
        <p>
          If you wish to exercise your rights under any applicable law,
          including the EU General Data Protection Regulation (GDPR) or the
          California Consumer Privacy Act (CCPA), such as the right to request
          access to, and rectification or erasure of your Personal Data held
          with AK, or to restrict or object to such Personal Data’s processing,
          or to port such Personal Data, or to opt-out of the sale of your
          personal information, or the right to equal services and prices (each
          to the extent available to you under the laws which apply to you) –
          please contact us by email at{' '}
          <Link to={'mailto:support@justaskhim.org'}>
            support@justaskhim.org
          </Link>
          .
        </p>
        <p>
          Please note that once you contact us by e-mail, we may require
          additional information and documents, including certain Personal Data,
          in order to authenticate and validate your identity and to process
          your request. Such additional data will be then retained by us for
          legal purposes (e.g. as proof of the identity of the person submitting
          the request), in accordance with Section 3 above.
        </p>
        <p>
          OpenWeb adheres to the Self-Regulatory Principles set forth by the
          European Interactive Digital Advertising Alliance (EDAA), and to the
          Interactive Advertising Bureau’s (IAB) Europe Online Behavioral
          Advertising Framework.
        </p>
        <h2>9. Children’s Privacy</h2>
        <p>
          <strong>
            Our Services are not designed to attract children under the age of
            16.
          </strong>{' '}
          We do not knowingly collect Personal Data from children and do not
          wish to do so. If we learn that a person under the age of 16 is using
          the Services, we will prohibit and block such use and will make all
          effort to promptly delete any Personal Data stored with us with regard
          to such child. If you believe that we might have any such data, please
          contact us by email at 
          <Link to={'mailto:support@justaskhim.org'}>
            support@justaskhim.org
          </Link>
          .
        </p>
        <h2>10. Data Controller/Processor</h2>
        <p>
          Certain data protection laws and regulations, such as the EU GDPR or
          the CCPA, typically distinguish between two main roles for parties
          processing Personal Data: the “Data Controller” (or under the CCPA,
          “business”), who determines the purposes and means of processing; and
          the “Data Processor” (or under the CCPA, “service provider”), who
          processes the data on behalf of the Data Controller. Below we explain
          how these roles apply to our Services, to the extent that such laws
          and regulations apply.
        </p>
        <p>
          Just Ask Him is typically the “Data Controller” of its Visitors’, its
          Users’ and its Partners’ Personal Data, and with respect to which,
          assumes the responsibilities of Data Controller (solely to the extent
          applicable under law), as set forth in this Privacy Policy. Under the
          CCPA, we are either the “business” with respect to Users’ Personal
          Data (if the User are directly engaged with us), or a “third party”
          (if such Users do not directly engage with us, whilst we process their
          personal information on our own behalf).
        </p>
        <p>
          In the relationship between a publishing Partner and Just Ask Him,
          such Partner shall act as “Data Controller” (or “business” under the
          CCPA) with respect to its own end-users and Users of their Partner
          Site(s). In certain instances, The Just Ask Him will only be deemed as
          the Data Processor (or service provider) regarding such end-users’
          Personal Data, if we process such end-users’ data on behalf of our
          Partner and in accordance with its reasonable instructions, subject to
          our Terms of Use and other commercial agreements.
        </p>
        <p>
          <strong>
            If you would like to make any requests or queries regarding Personal
            Data we process on our Partner’s behalf, please contact such Partner
            directly.
          </strong>
           For example, if you are an end-user of our Partner who wishes to
          access, correct, or delete data processed by Just Ask Him on behalf of
          such a Partner, please direct your request to the relevant Partner.
        </p>
        <h2>11. Additional Notices</h2>
        <ol>
          <li>
            <strong>Updates and Amendments:</strong> We may update and amend
            this Privacy Policy from time to time by posting an amended version
            on our Services. The amended version will be effective as of the
            date it is published. We will provide a 10-day prior notice if we
            believe any substantial changes are involved via any of the
            communication means available to us or via the Services. After such
            notice period, all amendments shall be deemed accepted by you.
          </li>
          <li>
            <strong>External Links:</strong> While our Services may contain
            links to other websites or services, we are not responsible for
            their privacy practices, and encourage you to pay attention when you
            leave our Services for the website or application of such third
            parties and to read the privacy policies of each and every website
            and service you visit. This Privacy Policy applies only to our
            Services.
          </li>
        </ol>
        <p>
          Please be advised that when you post links to/from YouTube, we make
          use of the YouTube API in order to support the preview of such
          content. Such activity is governed by 
          <Link to={'https://www.youtube.com/t/terms'} target='_blank'>
            YouTube TOS
          </Link>
           and 
          <Link to={'https://policies.google.com/privacy'} target='_blank'>
            Google Privacy Policy.
          </Link>{' '}
          We encourage you to review their practices carefully.
        </p>
        <p>
          <strong>Questions, Concerns or Complaints:</strong> If you have any
          additional comments or questions about this Privacy Policy or if you
          have any concerns regarding your Personal Data, please contact us by
          email at 
          <Link to={'mailto:support@justaskhim.org'}>
            support@justaskhim.org
          </Link>
          .
        </p>
      </Container>
    </PrivacyPolicyWrapper>
  );
};

export default PrivacyPolicy;
